import React, { useEffect } from 'react'
import styled from 'styled-components'
import { customMedia } from '../components/common/customMedia'
import { Reveal, Animation } from 'react-genie'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { Image } from '../components/common/Image'
import { Breadcrumb } from '../components/common/Breadcrumb'
import { SpOnly } from '../components/common/SpOnly'
import { PcOnly } from '../components/common/PcOnly'
import { Map } from '../components/common/Map'
import { StoreCard } from '../components/Store/StoreCard'

const IndexPage = () => {
  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash.slice(1)
      const element = document.getElementById(hash)

      const top = window.screen.width < 768 ? 57 : 180

      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop - top : 0
      })
    }, 100)
  })
  
  return (
    <Layout>
      <SEO 
        title='軽井沢エリアで５店舗展開している地域密着薬局 | シンビ堂'
        description='シンビ堂は軽井沢・中軽井沢・プリンスショッピングプラザ・御代田・借宿と軽井沢中心に5店舗展開する調剤薬局です。ドライブスルーや一般用医薬品・処方せん調剤など、正確かつスピーディーに対応します。お気軽にご相談ください。'
        keywords={['シンビ堂','店舗','薬局','軽井沢','御代田','中軽井沢',]}
      />
      <h1>店舗 薬局 軽井沢 御代田 中軽井沢 プリンスショッピングプラザ | シンビ堂</h1>

      <Map />
      <SpOnly>
        <BreadcrumbWrapper>
          <Breadcrumb color='blue' current='店舗案内' />
          </BreadcrumbWrapper>
        </SpOnly>
      <Container>
        <PcOnly>
          <BreadcrumbWrapper>
            <Breadcrumb color='white' current='店舗案内' />
          </BreadcrumbWrapper>
        </PcOnly>
        
        <Reveal delay={500} animation={Animation.FadeInUp}>
          <StoreCardWrapper id='karuizawahonten'>
            <StoreCard 
              imagePaths={['karuizawahonten.jpg', 'karuizawahonten1.jpg', 'karuizawahonten2.jpg']}
              storeType='HEAD PHARMACY'
              storeName='シンビ堂薬局 (軽井沢本店)'
              zip='389-0104'
              address1='長野県北佐久郡軽井沢町軽井沢東16-6'
              tel='0267-42-7887'
              fax='0267-42-3837'
              businessHours1='9:00〜18:00'
              holidays={`日曜日、祝日、年末年始\n(夏季、ゴールデンウィークは除く)`}
              features={['medicine', 'tablet']}
              mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.619650803135!2d138.63322931527114!3d36.3457669800447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601dd37e997aada7%3A0x97bac52dfcb57dc2!2z44K344Oz44OT5aCC6Jas5bGA!5e0!3m2!1sja!2sjp!4v1588948941262!5m2!1sja!2sjp'
            />
          </StoreCardWrapper>
        </Reveal>

        <Reveal delay={700} animation={Animation.FadeInUp}>
          <StoreCardWrapper id='shinbidodrugs'>
            <StoreCard 
              imagePaths={['shinbidodrugs.jpg', 'shinbidodrugs1.jpg', 'shinbidodrugs2.jpg']}
              storeType='PHARMACY'
              storeName='シンビ堂ドラッグス'
              zip='389-0102'
              address1='長野県北佐久郡軽井沢町軽井沢1178'
              address2='プリンスショッピングプラザ ウエスト'
              tel='0267-42-7068'
              fax='0267-42-7006'
              businessHours1='プリンスショッピングプラザに準ずる'
              holidays='プリンスショッピングプラザに準ずる'
              features={['tablet']}
              mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.880283897766!2d138.62983751527096!3d36.339451180046105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601dd37b792aebdd%3A0x8507b616cd96d3c9!2z44K344Oz44OT5aCC44OJ44Op44OD44Kw44K5!5e0!3m2!1sja!2sjp!4v1588948986514!5m2!1sja!2sjp'
            />
          </StoreCardWrapper>
        </Reveal>

        <Reveal delay={900} animation={Animation.FadeInUp}>
          <StoreCardWrapper id='nakakaruizawa'>
            <StoreCard 
              imagePaths={['nakakaruizawa.jpg', 'nakakaruizawa1.jpg', 'nakakaruizawa2.jpg']}
              storeType='PHARMACY'
              storeName='シンビ堂中軽井沢薬局'
              zip='389-0111'
              address1='長野県北佐久郡軽井沢町長倉2842-7'
              tel='0267-45-7520'
              fax='0267-45-7521'
              businessHours1='月･火･水･金 9:00～18:30'
              businessHours2='木 9:00～17:00 / 土 9:00～13:00'
              holidays='日曜･祝日･年末年始'
              features={['medicine', 'tablet', 'drivethru']}
              mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.691768509877!2d138.5888586152711!3d36.34401948004514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601dd24f26f28851%3A0x1aa65e85b56c6341!2z44K344Oz44OT5aCC5Lit6Lu95LqV5rKi6Jas5bGA!5e0!3m2!1sja!2sjp!4v1588949017512!5m2!1sja!2sjp" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'
            />
          </StoreCardWrapper>
        </Reveal>

        <Reveal delay={1100} animation={Animation.FadeInUp}>
          <StoreCardWrapper id='kariyado'>
            <StoreCard 
              imagePaths={['kariyado.jpg', 'kariyado1.jpg', 'kariyado2.jpg']}
              storeType='PHARMACY'
              storeName='シンビ堂借宿薬局'
              zip='389-0111'
              address1='長野県北佐久郡軽井沢町長倉4727-1'
              tel='0267-46-6280'
              fax='0267-46-6281'
              businessHours1='月〜金 9:00～19:00'
              businessHours2='土 9:00～13:30'
              holidays='日曜･祝日･年末年始'
              features={['medicine', 'tablet']}
              mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.736110645654!2d138.5641283152772!3d36.342944980045296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601dd20dd3254fd9%3A0x2de0c9c9c0a5eeb6!2z44K344Oz44OT5aCC5YCf5a6_6Jas5bGA!5e0!3m2!1sja!2sjp!4v1588949104219!5m2!1sja!2sjp'
            />
          </StoreCardWrapper>
        </Reveal>

        <Reveal delay={1300} animation={Animation.FadeInUp}>
          <StoreCardWrapper id='miyota'>
            <StoreCard 
              imagePaths={['miyota.jpg']}
              storeType='HEAD PHARMACY'
              storeName='シンビ堂御代田薬局'
              zip='389-0207'
              address1='長野県北佐久郡御代田町馬瀬口1743'
              address2='ツルヤみよたショッピングパーク'
              tel='0267-32-8622'
              fax='0267-32-8650'
              businessHours1='9:00～19:00'
              holidays='不定休(月2回 日曜か祝日)　1/1・2'
              features={['medicine', 'tablet', 'drivethru']}
              mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3214.409043791186!2d138.50780271527066!3d36.32663508004899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601dcc2f9d9a01af%3A0xbbda95149704697f!2z44K344Oz44OT5aCC5b6h5Luj55Sw6Jas5bGA!5e0!3m2!1sja!2sjp!4v1588949125905!5m2!1sja!2sjp'
              noBorder={true}
            />
          </StoreCardWrapper>
        </Reveal>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #00559F;
  overflow: hidden;
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    background-color: #fff;
    padding: 8px 10px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 12px 0 0 100px;
  `}
`

const StoreCardWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 800px;
  `}
`

const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

export default IndexPage
