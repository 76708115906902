import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'

import { Image } from '../../common/Image'
import { MyCarousel } from '../../common/MyCarousel'
import { SpBr } from '../../common/SpBr'

export interface Props {
  storeType: string
  storeName: string
  imagePaths: string[]
  zip: string
  address1: string
  address2?: string
  tel: string
  fax: string
  businessHours1: string
  businessHours2?: string
  holidays: string
  features: string[]
  mapSrc: string
  noBorder?: boolean
}

export const StoreCard = (props: Props) => {
  const { storeType, storeName, imagePaths, zip, address1, address2, tel, fax, businessHours1, businessHours2, holidays, features, mapSrc, noBorder } = props

  return (
    <Container>
      <StoreType>{storeType}</StoreType>
      <StoreName>{storeName}</StoreName>

      <StoreInfoContainer>
        <CarouselWrapper>
          <MyCarousel showArrows={false}>
            {imagePaths.map((imagePath, i) => <ImageWrapper key={i}><Image path={imagePath} /></ImageWrapper>)}
          </MyCarousel>
        </CarouselWrapper>
        <StoreInfoWrapper>
          <div>〒{zip}<br />{address1}{address2 && <><br />{address2}</>}</div>
          <Header>TEL</Header>
          <div>{tel}</div>
          <Header>FAX</Header>
          <div>{fax}</div>
          <Header>開局時間</Header>
          <div>{businessHours1}{businessHours2 && <><br />{businessHours2}</>}</div>
          <Header>定休日</Header>
          <div>{holidays.split('\n').map((m, i) => <div key={i}>{m}</div>)}</div>

          <FeatureContainer>
            {features.indexOf('medicine') >= 0 && <FeatureCard>
              <MedicineIconWrapper><Image path='store_medicine@2x.png' /></MedicineIconWrapper>
              <MedicineText>処方せん<SpBr />調剤</MedicineText>
            </FeatureCard>}
            {features.indexOf('tablet') >= 0 && <FeatureCard>
              <TabletIconWrapper><Image path='store_tablet@2x.png' /></TabletIconWrapper>
              <TabletText>一般医薬品<SpBr />販売</TabletText>
            </FeatureCard>}
            {features.indexOf('drivethru') >= 0 && <FeatureCard>
              <DrivethruIconWrapper><Image path='store_drivethru@2x.png' /></DrivethruIconWrapper>
              <DrivethruText>ドライブ<SpBr />スルー</DrivethruText>
            </FeatureCard>}
          </FeatureContainer>
        </StoreInfoWrapper>
      </StoreInfoContainer>
      <MapWrapper>
        <iframe src={mapSrc} frameBorder="0" allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>
      </MapWrapper>
      <Border noBorder={noBorder} />
    </Container>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  overflow: hidden;
`

const StoreType = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 30px;
  `}

  color: #fff;
  font-size: 1.6rem;
  font-family: Quicksand, sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
`

const StoreName = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    margin-top: 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.4rem;
    margin-top: 10px;
  `}

  color: #fff;
  font-family: Kosugi Maru, sans-serif;
  letter-spacing: 0.18em;
  text-align: center;
`

const StoreInfoContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    margin-top: 40px;
  `}
`

const CarouselWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    ul.control-dots {
      position: relative !important;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 50%;

    ul.control-dots {
      position: relative;
    }
  `}

  li.slide, li.slide.selected {
    background-color: #00559F;
  }
`

const ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  img {
    border-radius: 17.5px;
  }
`

const StoreInfoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    padding-left: 40px;
    width: 50%;
  `}
  
  color: #fff;
  font-size: 1.4rem;

  > div {
    letter-spacing: 0.1em;
    margin-bottom: 10px;
  }
`

const FeatureContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
`

const FeatureCard = styled.div`
  ${customMedia.lessThan("medium")`
    border-radius: 5px;
    height: 40px;
    width: 90px;

    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;

    div {
      letter-spacing: 0.16em;
    }
  `}
  ${customMedia.greaterThan("medium")`
    border-radius: 10px;
    height: 63px;
    width: 110px;

    div {
      letter-spacing: 0.08em;
    }
  `}

  background-color: #C42F68;
  color: #fff;
  margin-left: 5px;
  text-align: center;
  overflow: hidden;
`

const MedicineIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 10px auto 0;
    width: 28px;
  `}
`

const MedicineText = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 5px;
  `}

  font-size: 1.2rem;
`

const TabletIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 10px auto 0;
    width: 31px;
  `}
`

const TabletText = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 5px;
  `}

  font-size: 1.2rem;
`

const DrivethruIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 10px auto 0;
    width: 34px;
  `}
`

const DrivethruText = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 5px;
  `}

  font-size: 1.2rem;
`


const Header = styled.div`
  ${customMedia.lessThan("medium")`
    line-height: 20px;
    height: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    line-height: 22px;
    height: 22px;
  `}

  color: #00559F;
  background-color: #fff;
  border-radius: 13px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.24em !important;
`

const MapWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    iframe {
      margin-top: 15px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    iframe {
      margin-top: 40px;
      height: 240px;
    }
  `}

  iframe {
    border-radius: 20px;
    width: 100%;
  }
`

const Border = styled.div<{noBorder: boolean}>`
  ${customMedia.lessThan("medium")`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 40px;
  `}

  ${props => !props.noBorder && `
    background-color: #fff;
    height: 2px;
  `}
`

const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`