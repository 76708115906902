import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

export interface Props {
  showArrows: boolean
}

export interface OwnProps {
  children: ReactNode
}

export const MyCarousel = (props: Props & OwnProps) => {
  const { showArrows } = props
  const { children } = props

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer'
  }

  const indicatorStyles = {
    background: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
    width: 4,
    height: 4,
    display: 'inline-block',
    margin: '0 4px'
  }

  return (
    <Carousel
      renderArrowPrev={(onClickHandler, hasPrev, label) => {
        if (showArrows) {
          return (
            <PcOnly>
              <Button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 20 }}>
                <ArrowLeft />
              </Button>
            </PcOnly>
          )
        }
      }}
      renderArrowNext={(onClickHandler, hasNext, label) => {
        if (showArrows) {
          return (
            <>
              <SpOnly>
                <Button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15, top: 250 }}>
                  <ArrowRight />            
                </Button>
              </SpOnly>
              <PcOnly>
                <Button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 40 }}>
                  <ArrowRight />            
                </Button>
              </PcOnly>
            </>
          )
        }
      }}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: '#CA2F68' }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          )
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        )
      }}
      showStatus={false}
      infiniteLoop={true}
      showThumbs={false}
      autoPlay={true}
      interval={150000}
    >
      {children}
    </Carousel>
  )
}

const Button = styled.button`
  background: none;
  border: none;

  :focus {
    outline:0;
  }
`

const ArrowLeft = styled.div`
  position: relative;
  display: inline-block;
  padding-left: 20px;

  :before{
    content: '';
    width: 20px;
    height: 20px;
    border: 0px;
    border-bottom: solid 2px #fff;
    border-left: solid 2px #fff;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
  }
}`

const ArrowRight = styled.div`
  position: relative;
  display: inline-block;
  padding-left: 20px;

  :before{
    content: '';
    width: 20px;
    height: 20px;
    border: 0px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
  }
}`

const IndicatorWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display:none;
  `}
`